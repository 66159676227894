import { Typography, makeStyles, Theme, Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { Steps } from '../PreJoinScreens/PreJoinScreens';

const useStyles = makeStyles((theme: Theme) => ({
  gutterBottom: {
    marginBottom: '1em',
  },

  joinButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
      width: '100%',
      '& button': {
        margin: '0.5em 0',
      },
    },
  },
}));

interface GroudRulesScreenProps {
  setStep: (step: Steps) => void;
}

export default function GroudRulesScreen({ setStep }: GroudRulesScreenProps) {
  const classes = useStyles();
  const history = useHistory();

  return (
    <>
      <Typography variant="h5" className={classes.gutterBottom}>
        Ground Rules
      </Typography>
      <Typography variant="body1">
        To create a positive experience for everyone, we all need to follow some ground rules. Violations of those rules
        will result in temporary or permanent bans from the platform.
        <br />
        <br />
        <b>Be respectful:</b> Show respect for the opinions, beliefs, and backgrounds of others. Avoid interrupting or
        talking over others, and give others an opportunity to share their thoughts and experiences.
        <br />
        <br />
        <b>Be present:</b> Focus on the conversation and avoid multitasking or checking your phone. This shows respect
        for others and helps create a more engaging and productive conversation.
        <br />
        <br />
        <b>Be curious:</b> Approach the event with an open mind and a desire to learn about others. Ask thoughtful
        questions and listen actively to their responses.
        <br />
        <br />
        <b>Be inclusive:</b> Encourage diversity and inclusivity by being open to different perspectives and
        backgrounds. Avoid making assumptions based on someone's gender, race, religion, or sexual orientation.
        <br />
        <br />
        <b>No harassment:</b> Any form of harassment, including but not limited to, discrimination, bullying, or
        offensive language, will not be tolerated. This is a safe space for all participants, and everyone is expected
        to treat each other with respect.
        <br />
        <br />
        <b>No selling:</b> This is not the time or place to pitch your products or services. Instead, focus on building
        relationships and finding common interests with other participants.
        <br />
        <br />
        <b>Listen actively:</b> Listening actively means not only hearing what someone is saying, but also understanding
        their perspective and showing empathy. When you actively listen, you're able to connect with others on a deeper
        level and build stronger relationships.
        <br />
        <br />
        <b>Avoid dominating the conversation:</b> It's important to give everyone a chance to speak and share their
        thoughts. Try being mindful of your own speaking time and give your peer time to speak.
        <br />
        <br />
        <b>Be authentic:</b> Show up as your true self and be genuine in your interactions with others. Avoid trying to
        impress or "put on a show" for others, and instead, focus on building real, authentic relationships.
        <br />
        <br />
        <b>Maintain confidentiality:</b> What is discussed in the video chat should remain confidential. Participants
        should not share information or stories outside of the chat without the express permission of the person who
        shared it.
        <br />
        <br />
        <b>Practice gratitude:</b> Take a moment to express gratitude for the opportunity to connect with others and
        learn from their experiences. This helps create a positive, uplifting environment where people feel appreciated
        and valued.
        <br />
        <br />
        <b>Honor commitments:</b> If you make a commitment to follow up with someone after the event, honor that
        commitment. This helps build trust and fosters deeper relationships.
        <br />
        <br />
        By following these ground rules, you can help create a respectful, inclusive, and welcoming environment where
        people can form deeper connections and build meaningful relationships.
        <br />
        <br />
      </Typography>
      <div className={classes.joinButtons}>
        <Button
          variant="contained"
          color="primary"
          data-cy-accept-rules
          onClick={() => {
            setStep(Steps.deviceSelectionStep);
          }}
        >
          Back
        </Button>
      </div>
    </>
  );
}
