import { Typography, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  gutterBottom: {
    marginBottom: '1em',
  },
}));

export default function ErrorScreen() {
  const classes = useStyles();

  return (
    <>
      <Typography variant="h5" className={classes.gutterBottom}>
        Ooopss!
      </Typography>
      <Typography variant="body1">
        We are currently experiencing technical difficulties and apologize for the inconvenience. Our team is working
        diligently to resolve the issue and we hope to have everything back up and running as soon. Please check back
        later for updates on the situation. <br />
        <br />
        Thank you for your patience and understanding.
      </Typography>
    </>
  );
}
