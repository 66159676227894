import React from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useAppState } from '../../../state';

import { Button } from '@material-ui/core';

import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      //background: theme.brand,
      //color: 'white',
      '&:hover': {
        //background: '#600101',
      },
    },
  })
);

export default function NextCallButton(props: { className?: string }) {
  const classes = useStyles();
  const { room, setPeerDisconnectOpen } = useVideoContext();
  const { rejoinTopic, setRejoinTopic } = useAppState();
  const { setShowRateCallScreen } = useAppState();

  return (
    <Button
      onClick={() => {
        room!.disconnect();
        setPeerDisconnectOpen(false);
        setRejoinTopic(true);
        setShowRateCallScreen(true);
      }}
      className={clsx(classes.button, props.className)}
      variant="contained"
      color="primary"
      data-cy-disconnect
    >
      End Call
    </Button>
  );
}
