import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import axios from 'axios';
import { Typography, makeStyles, Theme, TextField, Button } from '@material-ui/core';
import { Steps, PreferredTopic } from '../PreJoinScreens';

var validator = require('validator');

const useStyles = makeStyles((theme: Theme) => ({
  gutterBottom: {
    marginBottom: '1em',
  },
  form: {
    marginTop: '2em',
    display: 'flex',
    alignItems: 'center',
  },
  formInput: {
    marginRight: '1em',
  },
  successMessage: {
    color: 'green',
    marginTop: '0.5em',
  },
  errorMessage: {
    color: 'red',
    marginTop: '0.5em',
  },
}));

interface NoTopicsActiveScreenProps {
  preferredTopic: PreferredTopic;

  setStep: (step: Steps) => void;
}

export default function NoTopicsActiveScreen({ preferredTopic, setStep }: NoTopicsActiveScreenProps) {
  const classes = useStyles();
  const [topics, setTopics] = useState<
    { topicname: string; displayname: string; startdate: string; minutes_until_start: number }[]
  >([]);
  const [email, setEmail] = useState('');
  const [isEmailValid, setEmailNameValid] = useState<boolean>(false); // Add state for validation
  const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false);
  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);

  const [screenTitle, setScreenTitle] = useState<string>(''); // Add state for validation
  const [topicErrorMessage, setTopicErrorMessage] = useState<string>('');

  //console.log('topicName:', preferredTopic.topicName);

  const data = preferredTopic.topicName && preferredTopic.isFound ? { topicname: preferredTopic.topicName } : {};
  //console.log('data:', data);

  useEffect(() => {
    // Send a POST request to the `/nexttopic` endpoint to get the list of topics

    axios
      .post(`${process.env.REACT_APP_BASE_ENDPOINT}nexttopic`, data)
      .then(response => {
        //console.log('response:', response.data);
        setTopics(response.data);
      })
      .catch(error => {
        console.error(error);
        setStep(Steps.error);
        return;
      });
  }, []);

  const getLocalTime = (utcDate: string) => {
    // Create a Date object from the UTC date string
    //console.log('utcDate:', utcDate);
    const date = new Date(utcDate);
    //console.log('date:', date);

    // Get the user's local time zone
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    //console.log('timeZone:', timeZone);

    // Create an Intl.DateTimeFormat object for the local time zone
    const formatter = new Intl.DateTimeFormat(undefined, {
      timeZone: timeZone,
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    });

    // Format the date in the local time zone
    const localDate = formatter.format(date);
    //console.log('localDate:', localDate);

    return localDate;
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    setEmailNameValid(validator.isEmail(event.target.value));
    setShowSuccessMessage(false);
    setShowErrorMessage(false);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    axios
      .post(`${process.env.REACT_APP_BASE_ENDPOINT}signup`, { email })
      .then(response => {
        if (response.data.status === 'success') {
          setEmail('');
          setShowSuccessMessage(true);
        } else {
          setShowErrorMessage(true);
        }
      })
      .catch(error => {
        console.error(error);
        setShowErrorMessage(true);
      });
  };

  //TODO: Handle the case when there are no upcoming events for a topic

  useEffect(() => {
    if (!preferredTopic.topicName) {
      setScreenTitle('No Mixers Available');
      setTopicErrorMessage(
        'We apologize, but there are currently no mixers available to join. The following mixers are scheduled:'
      );
    } else {
      if (preferredTopic.isFound) {
        setScreenTitle(`Topic "${preferredTopic.displayName}" is not active`);
        setTopicErrorMessage('The next instance is scheduled for:');
      } else {
        setScreenTitle(`Topic ${preferredTopic.topicName} is not found`);
        setTopicErrorMessage('The mixer you requested it not found, but the following mixers are scheduled:');
      }
    }
  }, [preferredTopic]);

  return (
    <>
      <Typography variant="h5" className={classes.gutterBottom}>
        {screenTitle}
      </Typography>
      <Typography variant="body1">
        {topicErrorMessage}
        <br />
      </Typography>
      {topics.length > 0 && (
        <ul>
          {topics.map(topic => (
            <li key={topic.topicname}>
              {topic.minutes_until_start < 0 ? (
                <>
                  <Link to={`/topic/${topic.topicname}`}>{topic.displayname}</Link> (Active Now!)
                </>
              ) : (
                <>
                  {topic.displayname}
                  <br /> {getLocalTime(topic.startdate)} (
                  {topic.minutes_until_start > 60
                    ? `${Math.floor(topic.minutes_until_start / 60)} hours`
                    : `${topic.minutes_until_start} minutes`}{' '}
                  until start)
                </>
              )}
            </li>
          ))}
        </ul>
      )}

      {!showSuccessMessage && (
        <Typography variant="body1">
          To stay up to date on the latests mixers, join our weekly newsletter.
          <br />
          <br />
        </Typography>
      )}
      <iframe
        src="https://embeds.beehiiv.com/96b26859-e854-452a-9e7c-24077a881a2c?slim=true"
        data-test-id="beehiiv-embed"
        height="52"
        frameBorder="0"
        scrolling="no"
      />
    </>
  );
}
