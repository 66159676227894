import React, { useState, useEffect, FormEvent } from 'react';
import DeviceSelectionScreen from './DeviceSelectionScreen/DeviceSelectionScreen';
import IntroContainer from '../IntroContainer/IntroContainer';
import MediaErrorSnackbar from './MediaErrorSnackbar/MediaErrorSnackbar';
import RoomNameScreen from './RoomNameScreen/RoomNameScreen';
import TopicNameScreen from './TopicNameScreen/TopicNameScreen';
import OverloadScreen from './OverloadScreen/OverloadScreen';
import NoTopicsAvailableScreen from './NoTopicsAvailableScreen/NoTopicsAvailableScreen';
import NoTopicsActiveScreen from './NoTopicsActiveScreen/NoTopicsActiveScreen';
import ErrorScreen from './ErrorScreen/ErrorScreen';

import { useAppState } from '../../state';
import { useParams } from 'react-router-dom';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';

import { CsUser } from '../../state/user/user';

import axios from 'axios';
import { wait } from '@testing-library/react';
import { noop } from 'lodash';
import GroudRulesScreen from '../GroundRulesScreen/GroundRulesScreen';
import RateCallScreen from '../RateCallScreen/RateCallScreen';

import { GApageView } from '../../index';

var validator = require('validator');

export enum Steps {
  roomNameStep = 'roomNameStep',
  deviceSelectionStep = 'deviceSelectionStep',
  topicNameStep = 'topicNameStep',
  overload = 'overload',
  noTopicsAvailable = 'noTopicsAvailable',
  noTopicsActive = 'noTopicsActive',
  error = 'error',
  showGroundRules = 'showGroundRules',
  rateCallScreen = 'rateCallScreen',
}

export interface DropdownOption {
  id: string;
  name: string;
}

export interface PreferredTopic {
  topicName: string | null;
  displayName: string | null;
  isActive: boolean | false;
  isFound: boolean | false;
}

export default function PreJoinScreens() {
  const { csUser, setCsUser } = useAppState();
  const { getAudioAndVideoTracks } = useVideoContext();
  const { URLRoomName } = useParams<{ URLRoomName?: string }>();
  const { URLTopicName } = useParams<{ URLTopicName?: string }>();

  const [step, setStep] = useState(Steps.topicNameStep);

  const [name, setName] = useState<string>(csUser?.displayName || '');

  const [roomName, setRoomName] = useState<string>('');
  const [topicName, setTopicName] = useState<string>('');

  const [preferredTopic, setPreferredTopic] = useState<PreferredTopic>({
    topicName: null,
    displayName: null,
    isActive: false,
    isFound: false,
  });

  const { globalTopicName, setGlobalTopicName } = useAppState();

  const [mediaError, setMediaError] = useState<Error>();

  //const allowRooms = new Set(['happy_hour', 'test_topic', 'timebased_test']);
  const [topics, setTopics] = useState<DropdownOption[]>([]);

  const { showRateCallScreen, setShowRateCallScreen } = useAppState();

  const url = `${process.env.REACT_APP_BASE_ENDPOINT}topics`;

  const setStepAndLog = (step: Steps) => {
    GApageView(`PreJoinScreens/${step}`);
    //console.log('setStepAndLog:', step);
    setStep(step);
  };

  useEffect(() => {
    const debugOutput = false;

    console.log('Welcome to CafeSocial.io! Have a great time!');

    if (debugOutput) {
      console.log('url', url);
      console.log('topicname:', topicName);
      console.log('globalTopicName:', globalTopicName);
      console.log('URLTopicName:', URLTopicName);
      console.log('!globalTopicName:', !globalTopicName);
    }

    const preferredTopic =
      globalTopicName || validator.isAlphanumeric(URLTopicName || '^invalid^', ['en-US'], { ignore: '-_.' })
        ? URLTopicName
        : false || topicName;

    if (debugOutput) console.log('preferredTopic:', preferredTopic);

    const data = preferredTopic ? { topicname: preferredTopic } : {};
    if (debugOutput) console.log('data:', data);

    interface Topic {
      topicname: any;
      displayname: any;
      isActive: boolean;
    }

    axios
      .post(url, data)
      .then(response => {
        if (response.data.Status === 'UNAVAILABLE') {
          // Handle case where service is unavailable
          setStepAndLog(Steps.overload);
          return;
        }

        const topicsData = response.data.map((item: Topic) => ({
          id: item.topicname, // Use topicname as id
          name: item.displayname, // Use displayname as name
          isActive: item.isActive,
        }));

        //console.log('topicsData', topicsData);

        if (topicsData.length === 0) {
          setStepAndLog(Steps.noTopicsAvailable);
          return;
        }

        const activeTopicsData = response.data
          .filter((item: { isActive: boolean }) => item.isActive)
          .map((item: Topic) => ({
            id: item.topicname, // Use topicname as id
            name: item.displayname, // Use displayname as name
            isActive: item.isActive,
          }));

        if (debugOutput) console.log('activeTopicsData', activeTopicsData);

        if (activeTopicsData.length === 0 && !preferredTopic) {
          //We have topics but they are not active yet
          setStepAndLog(Steps.noTopicsActive);
          return;
        }

        if (preferredTopic) {
          const topic = topicsData.find(
            (item: { id: any; displayname: any; isActive: boolean }) => item.id === preferredTopic
          );

          if (topic) {
            if (topic.isActive) {
              // the requested topic is found and active
              noop();
            } else {
              // the requested topic is found but not active
              setPreferredTopic({ topicName: preferredTopic, displayName: topic.name, isActive: false, isFound: true });
              setStepAndLog(Steps.noTopicsActive); // We have topics but they are not active yet
              return;
            }
          } else {
            // the requested topic is not found
            setPreferredTopic({ topicName: preferredTopic, displayName: null, isActive: false, isFound: false });
            setStepAndLog(Steps.noTopicsActive); // We have topics but they are not active yet
            return;
          }
        }

        //Set topics to active
        setTopics(activeTopicsData);
        setStepAndLog(Steps.topicNameStep);

        //At this point, we have filter out inactive and non-existing topics, so if we have a preferred topic, we can set it
        //If we don't have a preferred topic, we can set it to the first topic in the list
        if (preferredTopic) {
          setTopicName(preferredTopic);
        } else {
          setTopicName(topicsData[0].id);
        }

        // If we have a global topic state and no local state, set local topic state to global topic state
        /*if (globalTopicName && allowRooms.has(globalTopicName) && !topicName) {
          if (debugOutput) console.log('Set topic form globalTopic', globalTopicName);
          setTopicName(globalTopicName);
        }

        // If we have a URL topic param and no global topic state, set local topic state to URL param
        else if (URLTopicName && allowRooms.has(URLTopicName) && !globalTopicName) {
          setTopicName(URLTopicName);
          if (debugOutput) console.log('Set topic form URL param to', URLTopicName);  
        }

        // If we don't have a topicName and a globalTopicName, set it to the first topic in the list
        else if (topicsData.length > 0 && !topicName && !globalTopicName) {
          //setDefaultTopic(topicsData[1].id);
          if (debugOutput) console.log('No topic provided. Using default topic', topicsData[0].id);

          setTopicName(topicsData[0].id); // Set the topic name state variable to the default topic
        }*/

        // If we have a global topic state and a user name, go to device selection
        // This is needed to reconnect when a user presses next in a call
        if (globalTopicName && csUser?.displayName) {
          if (debugOutput)
            console.log('We have a globalTopic ', globalTopicName, ' and a username. Forwarding to device selection.');
          setTopicName(globalTopicName);

          if (showRateCallScreen) {
            setStepAndLog(Steps.rateCallScreen);
          } else {
            setStepAndLog(Steps.deviceSelectionStep);
          }
        }

        //We would show the topic name screen
        if (showRateCallScreen) {
          setStepAndLog(Steps.rateCallScreen);
        }
      })
      .catch(error => {
        console.error(error);
        setStepAndLog(Steps.error);
      });
  }, [URLTopicName]);
  // We do re-fetch the topics when the URLTopicName changes. This is needed when a user clicks a link to a topic

  useEffect(() => {
    if (step === Steps.deviceSelectionStep && !mediaError) {
      getAudioAndVideoTracks().catch(error => {
        console.log('Error acquiring local media:');
        console.dir(error);
        setMediaError(error);
      });
    }
  }, [getAudioAndVideoTracks, step, mediaError]);

  useEffect(() => {
    // console.log('useEffect: showRate' + showRateCallScreen);

    if (showRateCallScreen) {
      setStepAndLog(Steps.rateCallScreen);
    }
  }, [showRateCallScreen]);

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setCsUser(new CsUser(name));
    setGlobalTopicName(topicName);
    // If this app is deployed as a twilio function, don't change the URL because routing isn't supported.
    // @ts-ignore
    if (!window.location.origin.includes('twil.io') && !window.STORYBOOK_ENV) {
      window.history.replaceState(null, '', window.encodeURI(`/topic/${topicName}${window.location.search || ''}`));
    }
    setStepAndLog(Steps.deviceSelectionStep);
  };

  if (step === Steps.showGroundRules) {
    return (
      <IntroContainer scrollable>
        <GroudRulesScreen setStep={setStepAndLog}></GroudRulesScreen>
      </IntroContainer>
    );
  }

  return (
    <IntroContainer>
      <MediaErrorSnackbar error={mediaError} />
      {step === Steps.roomNameStep && (
        <RoomNameScreen
          name={name}
          roomName={roomName}
          setName={setName}
          setRoomName={setRoomName}
          handleSubmit={handleSubmit}
        />
      )}

      {step === Steps.error && <ErrorScreen />}
      {step === Steps.overload && <OverloadScreen />}
      {step === Steps.noTopicsAvailable && <NoTopicsAvailableScreen />}
      {step === Steps.noTopicsActive && (
        <NoTopicsActiveScreen preferredTopic={preferredTopic} setStep={setStepAndLog} />
      )}
      {step === Steps.rateCallScreen && <RateCallScreen setStep={setStepAndLog} />}

      {step === Steps.topicNameStep && (
        <TopicNameScreen
          name={name}
          setName={setName}
          topicName={topicName}
          setTopicName={setTopicName}
          topics={topics}
          setTopics={setTopics}
          handleSubmit={handleSubmit}
        />
      )}

      {step === Steps.deviceSelectionStep && (
        <DeviceSelectionScreen name={name} topicName={topicName} setStep={setStepAndLog} />
      )}
    </IntroContainer>
  );
}
