import React, { ChangeEvent, FormEvent, useReducer, useState, useEffect } from 'react';
import {
  Typography,
  makeStyles,
  TextField,
  Grid,
  Button,
  InputLabel,
  Theme,
  MenuItem,
  Select,
} from '@material-ui/core';
import { useAppState } from '../../../state';
import { User } from '@twilio/conversations';
import { CsUser } from '../../../state/user/user';
import { DropdownOption } from '../PreJoinScreens';

var validator = require('validator');

const useStyles = makeStyles((theme: Theme) => ({
  gutterBottom: {
    marginBottom: '1em',
  },
  inputContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '1.5em 0 3.5em',
    '& div:not(:last-child)': {
      marginRight: '1em',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '1.5em 0 2em',
    },
  },
  textFieldContainer: {
    width: '100%',
  },
  continueButton: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

interface TopicNameScreenProps {
  name: string;
  setName: (name: string) => void;
  topicName: string;
  setTopicName: (TopicName: string) => void;
  topics: DropdownOption[];
  setTopics: (topics: DropdownOption[]) => void;
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
}

export default function TopicNameScreen({
  name,
  setName,
  topicName,
  setTopicName,
  topics,
  setTopics,
  handleSubmit,
}: TopicNameScreenProps) {
  const classes = useStyles();
  const { user, setCsUser } = useAppState();

  const [defaultTopic, setDefaultTopic] = useState<string>('');
  const [isNameValid, setIsNameValid] = useState<boolean>(true); // Add state for validation

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
    if (event.target.value === '') {
      setIsNameValid(true); // Reset validation when the field is empty
    } else {
      setIsNameValid(validator.isAlphanumeric(event.target.value, ['en-US'], { ignore: '-_@.' }));
    }
  };

  const handleTopicNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setTopicName(event.target.value);
  };

  const handleSelectChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setTopicName(event.target.value as string);
  };

  const hasUsername = !window.location.search.includes('customIdentity=true') && user?.displayName;

  return (
    <>
      <Typography variant="h5" className={classes.gutterBottom}>
        Join a Mixer
      </Typography>
      <Typography variant="body1">
        {hasUsername
          ? "Enter the name of the topic you'd like to join."
          : "Enter your name and select the Mixer you'd like to join."}
      </Typography>
      <form onSubmit={handleSubmit}>
        <div className={classes.inputContainer}>
          {!hasUsername && (
            <div className={classes.textFieldContainer}>
              <InputLabel shrink htmlFor="input-user-name">
                Your Name
              </InputLabel>
              <TextField
                id="input-user-name"
                variant="outlined"
                fullWidth
                size="small"
                value={name}
                onChange={handleNameChange}
                error={!isNameValid} // Set error state based on validation
                helperText={
                  !isNameValid && 'Username must be alphanumeric and can contain the following special characters: -_@.'
                } // Display error message if validation fails
              />
            </div>
          )}
          <div className={classes.textFieldContainer}>
            <InputLabel shrink htmlFor="input-topic-name">
              Mixer
            </InputLabel>

            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={topicName}
              label="TopicName"
              onChange={handleSelectChange}
            >
              {topics.map(topic => (
                <MenuItem key={topic.id} value={topic.id}>
                  {topic.name}
                </MenuItem>
              ))}
            </Select>
          </div>
        </div>
        <Grid container justifyContent="flex-end">
          <Button
            variant="contained"
            type="submit"
            color="primary"
            disabled={!name || !topicName || !isNameValid} // Disable button if validation fails
            className={classes.continueButton}
          >
            Continue
          </Button>
        </Grid>
      </form>
    </>
  );
}
