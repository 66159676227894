import sha256 from 'crypto-js/sha256';

export class CsUser {
  displayName: string;
  userHash: string;
  profileUrl?: string;
  acceptedRules: boolean = false;

  constructor(displayName: string, profileUrl?: string) {
    this.displayName = displayName;
    this.userHash =
      displayName +
      sha256(displayName + Math.random())
        .toString()
        .substring(0, 16);
    this.profileUrl = profileUrl;
  }

  public getdisplayName(): string {
    return this.displayName;
  }

  public setAcceptedRules(): void {
    this.acceptedRules = true;
  }

  public hasAcceptedRules(): boolean {
    return this.acceptedRules;
  }
}
