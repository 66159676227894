import { Typography, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  gutterBottom: {
    marginBottom: '1em',
  },
}));

export default function NoTopicsAvailableScreen() {
  const classes = useStyles();

  return (
    <>
      <Typography variant="h5" className={classes.gutterBottom}>
        No Mixers Available
      </Typography>
      <Typography variant="body1">
        We apologize, but there are currently no mixers available to join. We are constantly updating our list of
        available mixers, so please check back later. <br />
        <br />
        Thank you for your interest and patience.
      </Typography>
    </>
  );
}
