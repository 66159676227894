import { Typography, makeStyles, Grid, Theme, Button } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { Steps } from '../PreJoinScreens/PreJoinScreens';
import { useAppState } from '../../state';

const useStyles = makeStyles((theme: Theme) => ({
  gutterBottom: {
    marginBottom: '1em',
  },
  joinButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
      width: '100%',
      '& button': {
        margin: '0.5em 0',
      },
    },
  },
}));

interface RateCallScreenProps {
  setStep: (step: Steps) => void;
}

export default function RateCallScreen({ setStep }: RateCallScreenProps) {
  const classes = useStyles();

  const { rejoinTopic, setRejoinTopic } = useAppState();
  const { user, setCsUser } = useAppState();

  //TODO make use of the param and adjust step accordingly
  useEffect(() => {
    //console.log('rejoinTopic', rejoinTopic);
    if (rejoinTopic) {
      //console.log('rejoinTopic', rejoinTopic);
    }
  }, [rejoinTopic]);

  return (
    <>
      <Typography variant="h5" className={classes.gutterBottom}>
        Call ended!
      </Typography>
      <Typography variant="body1">
        We hope you enjoyed the call.
        <br />
        <br />
        Select "Meet next person" to get connected to a new peer or "I'm done" to leave.
        <br />
        <br />
        Check out our{' '}
        <a href="https://cafesocialio.eventbrite.com" target="_blank">
          upcoming Mixers
        </a>{' '}
        or sign up for our{' '}
        <a href="https://cafesocialio.beehiiv.com/" target="_blank">
          Newsletter
        </a>{' '}
        to hear about the latest developments and about upcoming Events!
        <br />
        <br />
      </Typography>

      <Grid item md={7} sm={12} xs={12}>
        <Grid container direction="column" justifyContent="space-between" style={{ height: '100%' }}>
          <div className={classes.joinButtons}>
            <Button
              variant="contained"
              color="primary"
              data-cy-accept-rules
              onClick={() => {
                setStep(Steps.deviceSelectionStep);
              }}
            >
              Meet next person
            </Button>

            <Button
              variant="outlined"
              data-cy-accept-rules
              onClick={() => {
                setCsUser(null);
                setStep(Steps.topicNameStep);
              }}
            >
              I'm done
            </Button>
          </div>
        </Grid>
      </Grid>
    </>
  );
}
