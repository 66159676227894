import React, { ChangeEvent, useState, useEffect, useRef } from 'react';
import { makeStyles, Typography, Grid, Button, Theme, Hidden } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import LocalVideoPreview from './LocalVideoPreview/LocalVideoPreview';
import SettingsMenu from './SettingsMenu/SettingsMenu';
import { Steps } from '../PreJoinScreens';
import ToggleAudioButton from '../../Buttons/ToggleAudioButton/ToggleAudioButton';
import ToggleVideoButton from '../../Buttons/ToggleVideoButton/ToggleVideoButton';
import { useAppState } from '../../../state';
import useChatContext from '../../../hooks/useChatContext/useChatContext';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import { selectedParticipantContext } from '../../VideoProvider/useSelectedParticipant/useSelectedParticipant';
import Room from '../../Room/Room';
import { useInterval } from 'usehooks-ts';

import { GApageView } from '../../../index';

const useStyles = makeStyles((theme: Theme) => ({
  gutterBottom: {
    marginBottom: '1em',
  },
  marginTop: {
    marginTop: '1em',
  },
  deviceButton: {
    width: '100%',
    border: '2px solid #aaa',
    margin: '1em 0',
  },
  localPreviewContainer: {
    paddingRight: '2em',
    [theme.breakpoints.down('sm')]: {
      padding: '0 2.5em',
    },
  },
  joinButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
      width: '100%',
      '& button': {
        margin: '0.5em 0',
      },
    },
  },
  mobileButtonBar: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'space-between',
      margin: '1.5em 0 1em',
    },
  },
  mobileButton: {
    padding: '0.8em 0',
    margin: 0,
  },
}));

interface DeviceSelectionScreenProps {
  name: string;
  topicName: string;
  setStep: (step: Steps) => void;
}

export default function DeviceSelectionScreen({ name, topicName, setStep }: DeviceSelectionScreenProps) {
  const classes = useStyles();
  const { getToken, findMatch, getRoom, removeFromWaitlist, isFetching } = useAppState();

  const [isWaitingForPeer, setIsWaitingForPeer] = useState<boolean>(false);
  const [hasAcceptedRules, setHasAcceptedRules] = useState<boolean>(false);

  const { connect: chatConnect } = useChatContext();
  const {
    connect: videoConnect,
    isAcquiringLocalTracks,
    isConnecting,
    removeLocalVideoTrack,
    removeLocalAudioTrack,
  } = useVideoContext();

  const disableButtons = isFetching || isAcquiringLocalTracks || isConnecting;

  // Dynamic delay
  const [delay, setDelay] = useState<number>(2000);
  // ON/OFF
  const [isPolling, setPolling] = useState<boolean>(false);

  const { rejoinTopic, setRejoinTopic } = useAppState();
  const { csUser, setCsUser } = useAppState();

  const userHash = csUser?.userHash || ' ' + Math.random();
  const displayName = csUser?.displayName || '<unknown>';

  useInterval(
    () => {
      findRoom();
    },
    // Delay in milliseconds or null to stop it
    isPolling ? delay : null
  );

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setDelay(Number(event.target.value));
  };

  const findPeer = () => {
    GApageView(`PreJoinScreens/findPeer`);
    findMatch(userHash, topicName);
    setIsWaitingForPeer(true);
    setPolling(true);
  };

  const disconnectLocalTracks = () => {
    removeLocalVideoTrack();
    removeLocalAudioTrack();
    removeFromWaitlist(userHash, topicName);
    setStep(Steps.topicNameStep);
  };

  const findRoom = () => {
    getRoom(userHash, topicName).then(({ RoomName }) => {
      if (RoomName == undefined || RoomName == '') {
        return;
      }
      getToken(userHash, RoomName).then(({ token }) => {
        setPolling(false);
        videoConnect(token);
        removeFromWaitlist(userHash, topicName);
        process.env.REACT_APP_DISABLE_TWILIO_CONVERSATIONS !== 'true' && chatConnect(token);
      });
    });
  };

  useEffect(() => {
    if (rejoinTopic) {
      setRejoinTopic(false);
      findPeer();
    }
  }, [rejoinTopic]);

  useEffect(() => {
    //console.log('useEffect: hasAcceptedRules' + csUser?.hasAcceptedRules());
    if (csUser?.hasAcceptedRules()) {
      setHasAcceptedRules(true);
    }
  }, [csUser?.hasAcceptedRules(), hasAcceptedRules]);

  if (!hasAcceptedRules) {
    return (
      <>
        <Typography variant="h5" className={classes.gutterBottom}>
          Ground Rules
        </Typography>

        <Typography variant="body1" className={classes.gutterBottom}>
          To make this Mixer a positive experience for everyone, we all need to follow some ground rules:
        </Typography>

        <Typography variant="body1" className={classes.gutterBottom}>
          {'\u2714'} Be respectful
          <br />
          {'\u2714'} Be present
          <br />
          {'\u2714'} Be curious
          <br />
          {'\u2714'} Be inclusive
          <br />
          {'\u2714'} Be authentic
          <br />
          {'\u2716'} No harassment
          <br />
          {'\u2716'} No selling
          <br />
          <br />
          For the full ground rule document, please visit{' '}
          <a href="#" onClick={() => setStep(Steps.showGroundRules)}>
            link
          </a>
          .<br />
          Click "Accept" to continue or "Cancel" to exit.
        </Typography>

        <Typography variant="body1" className={classes.gutterBottom}></Typography>

        <Grid item md={5} sm={12} xs={12} style={{ marginLeft: 'auto' }}>
          <Grid container direction="column" justifyContent="space-between" style={{ height: '100%' }}>
            <div className={classes.joinButtons}>
              <Button variant="outlined" color="primary" onClick={() => disconnectLocalTracks()}>
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                data-cy-accept-rules
                onClick={() => {
                  if (csUser) {
                    csUser.setAcceptedRules();
                    setHasAcceptedRules(true);
                  }
                }}
                disabled={disableButtons}
              >
                Accept
              </Button>
            </div>
          </Grid>
        </Grid>
      </>
    );
  }

  if (isFetching || isConnecting) {
    return (
      <Grid container justifyContent="center" alignItems="center" direction="column" style={{ height: '100%' }}>
        <div>
          <CircularProgress variant="indeterminate" />
        </div>
        <div>
          <Typography variant="body2" style={{ fontWeight: 'bold', fontSize: '16px' }}>
            Joining Meeting
          </Typography>
        </div>
      </Grid>
    );
  }

  if (isWaitingForPeer) {
    return (
      <Grid container justifyContent="center" alignItems="center" direction="column" style={{ height: '100%' }}>
        <div>
          <CircularProgress variant="indeterminate" />
        </div>
        <div>
          <Typography variant="body2" style={{ fontWeight: 'bold', fontSize: '16px' }}>
            Waiting for peer to join...
          </Typography>
          <br />
          If there are very few people online, you may have to wait a few minutes for a peer to join.
          <br />
          <br />
          While you are waiting, check out our{' '}
          <a href="https://cafesocialio.eventbrite.com" target="_blank">
            upcoming Mixers
          </a>{' '}
          or sign up for our{' '}
          <a href="https://cafesocialio.beehiiv.com/" target="_blank">
            Newsletter
          </a>{' '}
          to hear about the latest developments and about upcoming Events!
          <br />
          <br />
        </div>
        <div className={classes.joinButtons}>
          <Button variant="outlined" color="primary" onClick={() => disconnectLocalTracks()}>
            Cancel
          </Button>
        </div>
      </Grid>
    );
  }

  return (
    <>
      <Typography variant="h5" className={classes.gutterBottom}>
        Join {topicName}
      </Typography>

      <Grid container justifyContent="center">
        <Grid item md={7} sm={12} xs={12}>
          <div className={classes.localPreviewContainer}>
            <LocalVideoPreview identity={displayName} />
          </div>
          <div className={classes.mobileButtonBar}>
            <Hidden mdUp>
              <ToggleAudioButton className={classes.mobileButton} disabled={disableButtons} />
              <ToggleVideoButton className={classes.mobileButton} disabled={disableButtons} />
            </Hidden>
            <SettingsMenu mobileButtonClass={classes.mobileButton} />
          </div>
        </Grid>
        <Grid item md={5} sm={12} xs={12}>
          <Grid container direction="column" justifyContent="space-between" style={{ height: '100%' }}>
            <div>
              <Hidden smDown>
                <ToggleAudioButton className={classes.deviceButton} disabled={disableButtons} />
                <ToggleVideoButton className={classes.deviceButton} disabled={disableButtons} />
              </Hidden>
            </div>
            <div className={classes.joinButtons}>
              <Button variant="outlined" color="primary" onClick={() => disconnectLocalTracks()}>
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                data-cy-join-now
                onClick={() => findPeer()}
                disabled={disableButtons}
              >
                Join Now
              </Button>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
