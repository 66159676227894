import { Typography, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  gutterBottom: {
    marginBottom: '1em',
  },
}));

export default function OverloadScreen({}) {
  const classes = useStyles();

  return (
    <>
      <Typography variant="h5" className={classes.gutterBottom}>
        At capacity
      </Typography>
      <Typography variant="body1">
        We apologize for the inconvenience while trying to access our services. Unfortunately, we have reached our
        maximum capacity at the moment and are currently working on scaling up our infrastructure to handle increased
        demand. <br />
        <br />
        Please check back at a later point.
        <br />
        <br />
        Thank you for your patience and understanding.
      </Typography>
    </>
  );
}
