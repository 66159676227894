import React, { createContext, useContext, useReducer, useState } from 'react';
import { RecordingRules, RoomType } from '../types';
import { TwilioError } from 'twilio-video';
import { settingsReducer, initialSettings, Settings, SettingsAction } from './settings/settingsReducer';
import useActiveSinkId from './useActiveSinkId/useActiveSinkId';
import useFirebaseAuth from './useFirebaseAuth/useFirebaseAuth';
import { useLocalStorageState } from '../hooks/useLocalStorageState/useLocalStorageState';
import usePasscodeAuth from './usePasscodeAuth/usePasscodeAuth';
import { User } from 'firebase/auth';

import { CsUser } from './user/user';

export interface StateContextType {
  error: TwilioError | Error | null;
  setError(error: TwilioError | Error | null): void;
  getToken(name: string, room: string, passcode?: string): Promise<{ room_type: RoomType; token: string }>;
  findMatch(name: string, topic: string): Promise<{ response: string }>;
  getRoom(name: string, topic: string): Promise<{ Status: string; RoomName?: string }>;
  removeFromWaitlist(name: string, topic: string): Promise<{ Status: string; RoomName?: string }>;
  user?: User | null | { displayName: undefined; photoURL: undefined; passcode?: string };
  csUser?: CsUser | null;
  setCsUser(user: CsUser | null): void;
  signIn?(passcode?: string): Promise<void>;
  signOut?(): Promise<void>;
  isAuthReady?: boolean;
  isFetching: boolean;
  showRateCallScreen: boolean | false;
  setShowRateCallScreen(showRateCallScreen: boolean): void;
  activeSinkId: string;
  setActiveSinkId(sinkId: string): void;
  settings: Settings;
  dispatchSetting: React.Dispatch<SettingsAction>;
  roomType?: RoomType;
  roomName: string;
  globalTopicName: string | null;
  setGlobalTopicName(topicName: string | null): void;
  rejoinTopic: boolean;
  setRejoinTopic(rejoinTopic: boolean): void;
  updateRecordingRules(room_sid: string, rules: RecordingRules): Promise<object>;
  isGalleryViewActive: boolean;
  setIsGalleryViewActive: React.Dispatch<React.SetStateAction<boolean>>;
  maxGalleryViewParticipants: number;
  setMaxGalleryViewParticipants: React.Dispatch<React.SetStateAction<number>>;
}

export const StateContext = createContext<StateContextType>(null!);

/*
  The 'react-hooks/rules-of-hooks' linting rules prevent React Hooks from being called
  inside of if() statements. This is because hooks must always be called in the same order
  every time a component is rendered. The 'react-hooks/rules-of-hooks' rule is disabled below
  because the "if (process.env.REACT_APP_SET_AUTH === 'firebase')" statements are evaluated
  at build time (not runtime). If the statement evaluates to false, then the code is not
  included in the bundle that is produced (due to tree-shaking). Thus, in this instance, it
  is ok to call hooks inside if() statements.
*/
export default function AppStateProvider(props: React.PropsWithChildren<{}>) {
  const [error, setError] = useState<TwilioError | null>(null);
  const [isFetching, setIsFetching] = useState(false);
  const [showRateCallScreen, setShowRateCallScreen] = useState(false);
  const [isGalleryViewActive, setIsGalleryViewActive] = useLocalStorageState('gallery-view-active-key', true);
  const [activeSinkId, setActiveSinkId] = useActiveSinkId();
  const [settings, dispatchSetting] = useReducer(settingsReducer, initialSettings);
  const [csUser, setCsUser] = useState<{ displayName: string; photoURL?: string; passcode?: string } | null>();
  const [roomType, setRoomType] = useState<RoomType>();
  const [roomName, setRoomName] = useState<String>();
  const [globalTopicName, setGlobalTopicName] = useState<String | null>();
  const [rejoinTopic, setRejoinTopic] = useState(false);

  const [maxGalleryViewParticipants, setMaxGalleryViewParticipants] = useLocalStorageState(
    'max-gallery-participants-key',
    6
  );

  let contextValue = {
    error,
    setError,
    csUser,
    setCsUser,
    isFetching,
    showRateCallScreen,
    setShowRateCallScreen,
    activeSinkId,
    setActiveSinkId,
    settings,
    dispatchSetting,
    roomType,
    roomName,
    globalTopicName,
    setGlobalTopicName,
    rejoinTopic,
    setRejoinTopic,
    isGalleryViewActive,
    setIsGalleryViewActive,
    maxGalleryViewParticipants,
    setMaxGalleryViewParticipants,
  } as StateContextType;

  if (process.env.REACT_APP_SET_AUTH === 'firebase') {
    contextValue = {
      ...contextValue,
      ...useFirebaseAuth(), // eslint-disable-line react-hooks/rules-of-hooks
    };
  } else if (process.env.REACT_APP_SET_AUTH === 'passcode') {
    contextValue = {
      ...contextValue,
      ...usePasscodeAuth(), // eslint-disable-line react-hooks/rules-of-hooks
    };
  } else {
    contextValue = {
      ...contextValue,
      getToken: async (user_identity, room_name) => {
        const endpoint = process.env.REACT_APP_TOKEN_ENDPOINT || '/token';

        return fetch(endpoint, {
          method: 'POST',
          headers: {
            'content-type': 'application/json',
          },
          body: JSON.stringify({
            user_identity,
            room_name,
            create_conversation: process.env.REACT_APP_DISABLE_TWILIO_CONVERSATIONS !== 'true',
          }),
        }).then(res => res.json());
      },
      findMatch: async (id, topic) => {
        const endpoint = process.env.REACT_APP_BASE_ENDPOINT;
        return fetch(endpoint + 'match', {
          method: 'POST',
          headers: {
            'content-type': 'application/json',
          },
          body: JSON.stringify({
            id,
            topic,
          }),
        }).then(res => res.json());
      },
      getRoom: async (id, topic) => {
        const endpoint = process.env.REACT_APP_BASE_ENDPOINT;
        return fetch(endpoint + 'getRoom', {
          method: 'POST',
          headers: {
            'content-type': 'application/json',
          },
          body: JSON.stringify({
            id,
            topic,
          }),
        }).then(res => res.json());
      },
      removeFromWaitlist: async (id, topic) => {
        const endpoint = process.env.REACT_APP_BASE_ENDPOINT;
        return fetch(endpoint + 'removeFromWaitlist', {
          method: 'POST',
          headers: {
            'content-type': 'application/json',
          },
          body: JSON.stringify({
            id,
            topic,
          }),
        }).then(res => res.json());
      },

      updateRecordingRules: async (room_sid, rules) => {
        const endpoint = process.env.REACT_APP_TOKEN_ENDPOINT || '/recordingrules';

        return fetch(endpoint, {
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ room_sid, rules }),
          method: 'POST',
        })
          .then(async res => {
            const jsonResponse = await res.json();

            if (!res.ok) {
              const recordingError = new Error(
                jsonResponse.error?.message || 'There was an error updating recording rules'
              );
              recordingError.code = jsonResponse.error?.code;
              return Promise.reject(recordingError);
            }

            return jsonResponse;
          })
          .catch(err => setError(err));
      },
    };
  }

  const getToken: StateContextType['getToken'] = (name, room) => {
    setIsFetching(true);
    return contextValue
      .getToken(name, room)
      .then(res => {
        setRoomType(res.room_type);
        setIsFetching(false);
        return res;
      })
      .catch(err => {
        setError(err);
        setIsFetching(false);
        return Promise.reject(err);
      });
  };

  const findMatch: StateContextType['findMatch'] = (name, room) => {
    //setIsFetching(true);

    return contextValue
      .findMatch(name, room)
      .then(res => {
        //setRoomType(res.room_type);
        //setIsFetching(false);

        return res;
      })
      .catch(err => {
        setError(err);
        setIsFetching(false);
        return Promise.reject(err);
      });
  };

  const getRoom: StateContextType['getRoom'] = (name, topic) => {
    //setIsFetching(true);

    return contextValue
      .getRoom(name, topic)
      .then(res => {
        if (res.Status == 'OK') {
          setRoomName(res.RoomName);
        } else {
          setRoomName('');
        }
        //setRoomType(res.room_type);
        //setIsFetching(false);
        return res;
      })
      .catch(err => {
        setError(err);
        setIsFetching(false);
        return Promise.reject(err);
      });
  };

  const removeFromWaitlist: StateContextType['removeFromWaitlist'] = (name, room) => {
    //setIsFetching(true);

    return contextValue
      .removeFromWaitlist(name, room)
      .then(res => {
        return res;
      })
      .catch(err => {
        setError(err);
        setIsFetching(false);
        return Promise.reject(err);
      });
  };

  const updateRecordingRules: StateContextType['updateRecordingRules'] = (room_sid, rules) => {
    setIsFetching(true);
    return contextValue
      .updateRecordingRules(room_sid, rules)
      .then(res => {
        setIsFetching(false);
        return res;
      })
      .catch(err => {
        setError(err);
        setIsFetching(false);
        return Promise.reject(err);
      });
  };

  return (
    <StateContext.Provider
      value={{ ...contextValue, getToken, findMatch, getRoom, removeFromWaitlist, updateRecordingRules }}
    >
      {props.children}
    </StateContext.Provider>
  );
}

export function useAppState() {
  const context = useContext(StateContext);
  if (!context) {
    throw new Error('useAppState must be used within the AppStateProvider');
  }
  return context;
}
