import React, { SVGProps } from 'react';

export default function CafeSocialLogo(props: SVGProps<SVGSVGElement>) {
  return (
    <svg fill="#F22F46" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 500 500" {...props}>
      <defs>
        <linearGradient id="myGradient" x1="0.15" y1="0.85" x2="0.85" y2="0.15">
          <stop offset="0%" stopColor="#051dff" />
          <stop offset="100%" stopColor="#b500ff" />
        </linearGradient>
      </defs>

      <circle cx="250" cy="250" r="240" stroke="#ffffff" strokeWidth="3" fill="none" />

      <circle cx="490" cy="250" r="4" stroke="#ffffff" strokeWidth="3" fill="#ffffff" />
      <circle cx="457.846096908265" cy="370" r="4" stroke="#ffffff" strokeWidth="3" fill="#ffffff" />
      <circle cx="370" cy="457.846096908265" r="4" stroke="#ffffff" strokeWidth="3" fill="#ffffff" />
      <circle cx="250." cy="490" r="4" stroke="#ffffff" strokeWidth="3" fill="#ffffff" />
      <circle cx="130." cy="457.846096908265" r="4" stroke="#ffffff" strokeWidth="3" fill="#ffffff" />
      <circle cx="42.1539030917347" cy="370" r="4" stroke="#ffffff" strokeWidth="3" fill="#ffffff" />
      <circle cx="10" cy="250." r="4" stroke="#ffffff" strokeWidth="3" fill="#ffffff" />
      <circle cx="42.1539030917348" cy="130." r="4" stroke="#ffffff" strokeWidth="3" fill="#ffffff" />
      <circle cx="130." cy="42.1539030917348" r="4" stroke="#ffffff" strokeWidth="3" fill="#ffffff" />
      <circle cx="250." cy="10" r="4" stroke="#ffffff" strokeWidth="3" fill="#ffffff" />
      <circle cx="370" cy="42.1539030917348" r="4" stroke="#ffffff" strokeWidth="3" fill="#ffffff" />
      <circle cx="457.846096908265" cy="130." r="4" stroke="#ffffff" strokeWidth="3" fill="#ffffff" />

      <line x1="490" y1="250" x2="370" y2="457.846096908265" stroke="#ffffff" strokeWidth="3" />
      <line x1="457.846096908265" y1="370" x2="250." y2="490" stroke="#ffffff" strokeWidth="3" />
      <line x1="370" y1="457.846096908265" x2="130." y2="457.846096908265" stroke="#ffffff" strokeWidth="3" />
      <line x1="250." y1="490" x2="42.1539030917347" y2="370" stroke="#ffffff" strokeWidth="3" />
      <line x1="130." y1="457.846096908265" x2="10" y2="250." stroke="#ffffff" strokeWidth="3" />
      <line x1="42.1539030917347" y1="370" x2="42.1539030917348" y2="130." stroke="#ffffff" strokeWidth="3" />
      <line x1="10" y1="250." x2="130." y2="42.1539030917348" stroke="#ffffff" strokeWidth="3" />
      <line x1="42.1539030917348" y1="130." x2="250." y2="10" stroke="#ffffff" strokeWidth="3" />
      <line x1="130." y1="42.1539030917348" x2="370" y2="42.1539030917348" stroke="#ffffff" strokeWidth="3" />
      <line x1="250." y1="10" x2="457.846096908265" y2="130." stroke="#ffffff" strokeWidth="3" />
      <line x1="370" y1="42.1539030917348" x2="490" y2="250" stroke="#ffffff" strokeWidth="3" />
      <line x1="457.846096908265" y1="130." x2="457.846096908265" y2="370" stroke="#ffffff" strokeWidth="3" />

      <line x1="490" y1="250" x2="250." y2="490" stroke="#ffffff" strokeWidth="3" />
      <line x1="457.846096908265" y1="370" x2="130." y2="457.846096908265" stroke="#ffffff" strokeWidth="3" />
      <line x1="370" y1="457.846096908265" x2="42.1539030917347" y2="370" stroke="#ffffff" strokeWidth="3" />
      <line x1="250." y1="490" x2="10" y2="250." stroke="#ffffff" strokeWidth="3" />
      <line x1="130." y1="457.846096908265" x2="42.1539030917348" y2="130." stroke="#ffffff" strokeWidth="3" />
      <line x1="42.1539030917347" y1="370" x2="130." y2="42.1539030917348" stroke="#ffffff" strokeWidth="3" />
      <line x1="10" y1="250." x2="250." y2="10" stroke="#ffffff" strokeWidth="3" />
      <line x1="42.1539030917348" y1="130." x2="370" y2="42.1539030917348" stroke="#ffffff" strokeWidth="3" />
      <line x1="130." y1="42.1539030917348" x2="457.846096908265" y2="130." stroke="#ffffff" strokeWidth="3" />
      <line x1="250." y1="10" x2="490" y2="250" stroke="#ffffff" strokeWidth="3" />

      <line x1="370" y1="42.1539030917348" x2="457.846096908265" y2="370" stroke="#ffffff" strokeWidth="3" />
      <line x1="457.846096908265" y1="130." x2="370" y2="457.846096908265" stroke="#ffffff" strokeWidth="3" />
      <line x1="490" y1="250" x2="130." y2="457.846096908265" stroke="#ffffff" strokeWidth="3" />
      <line x1="457.846096908265" y1="370" x2="42.1539030917347" y2="370" stroke="#ffffff" strokeWidth="3" />
      <line x1="370" y1="457.846096908265" x2="10" y2="250." stroke="#ffffff" strokeWidth="3" />
      <line x1="250." y1="490" x2="42.1539030917348" y2="130." stroke="#ffffff" strokeWidth="3" />
      <line x1="130." y1="457.846096908265" x2="130." y2="42.1539030917348" stroke="#ffffff" strokeWidth="3" />
      <line x1="42.1539030917347" y1="370" x2="250." y2="10" stroke="#ffffff" strokeWidth="3" />
      <line x1="10" y1="250." x2="370" y2="42.1539030917348" stroke="#ffffff" strokeWidth="3" />
      <line x1="42.1539030917348" y1="130." x2="457.846096908265" y2="130." stroke="#ffffff" strokeWidth="3" />
      <line x1="130." y1="42.1539030917348" x2="490" y2="250" stroke="#ffffff" strokeWidth="3" />
      <line x1="250." y1="10" x2="457.846096908265" y2="370" stroke="#ffffff" strokeWidth="3" />
      <line x1="370" y1="42.1539030917348" x2="370" y2="457.846096908265" stroke="#ffffff" strokeWidth="3" />
      <line x1="457.846096908265" y1="130." x2="250." y2="490" stroke="#ffffff" strokeWidth="3" />
    </svg>
  );
}
