import React, { PropsWithChildren } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import EndCallButton from '../Buttons/EndCallButton/EndCallButton';
import NextCallButton from '../Buttons/NextCallButton/NextCallButton';

import Divider from '@material-ui/core/Divider';
import packageJSON from '../../../package.json';

import Video from 'twilio-video';
import { useAppState } from '../../state';

interface PeerDisconnectProps {
  open: boolean;
  onClose(): void;
}

function PeerDisconnectDialog({ open, onClose }: PropsWithChildren<PeerDisconnectProps>) {
  const { roomType, roomName, globalTopicName } = useAppState();
  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="xs">
      <DialogTitle>Your peer left</DialogTitle>
      <Divider />
      <DialogContent>
        Your peer has left the conversation. <br />
        <br />
        Select "Next" to get connected the a new peer or "Disconnect" to leave.
      </DialogContent>
      <Divider />
      <DialogActions>
        <NextCallButton />
        <EndCallButton />
      </DialogActions>
    </Dialog>
  );
}

export default PeerDisconnectDialog;
